import React from 'react'
import './SusYarn.css'
import Synt1 from'../img/sustainable.png'
import tab10 from'../img/tab10.jpeg'

function SusYarn() {
  return (
    <div>
         <div  class="bac-cont">
    <img  class="ba-imag"  src={Synt1}/>
    <div class="h">
    <h1 class="he">Sustainable Yarns</h1>
    </div>
    </div>
    <div class="tc">
      <h4 class="hp-1">Sustainable yarns–</h4>
      <p class="pa"> Sustainable yarns are yarns that are produced in an environmentally friendly and socially responsible manner. These yarns can be made from natural fibers like organic cotton, bamboo, or hemp, or from recycled or repurposed materials like plastic bottles or textile scraps.Sustainable yarns have a wide range of applications, including clothing, home textiles, and industrial fabrics.Using sustainable yarns in textile production can help to reduce the environmental impact of the fashion and textile industry, and also promote sustainable agricultural practices and preserve natural resources. Additionally, sustainable yarns can also have social benefits, such as providing fair wages and safe working conditions for workers in the textile industry.</p>
    </div>

<div class="t">
  <img class="tl" src={tab10}/>
</div>

    </div>
  )
}

export default SusYarn
