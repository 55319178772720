import React from 'react'
import './SynYarn.css'
import Syn from '../img/synthetic.png'
import tab5 from '../img/tab5.jpeg'


function SynYarn() {
  return (
    <div>
        <div  class="bacc-container">
    <img  class="back-imagg"  src={Syn}/>
    <div class="homm">
    <h1 class="heap">Synthetic Yarn</h1>
    </div>
    </div>
    <div class="tabcc">
      <h4 class="hpp">Synthetic yarns–</h4>
      <p class="parr"> Synthetic yarns are yarns made from man-made fibers, rather than natural fibers like cotton or wool. These fibers are created by extruding a polymer through a spinneret, which forms the fibers into a yarn. Synthetic yarns are often more durable and resistant to shrinking and wrinkling than natural fibers, and they can also be produced at a lower cost. Some examples of synthetic fibers include polyester, nylon, and acrylic. They are used for a variety of applications, including clothing, upholstery, and industrial fabrics.</p>

    </div>

<div class="tabss">
  <img class="tabl1" src={tab5}/>
</div>

    </div>
  )
}

export default SynYarn
