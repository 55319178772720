import React from 'react'
import Caraousel from './Homepage/Caraousel'
import img1 from './Homepage/image (1).png';
import img2 from '../img/home.png';
import img5 from '../img/home1.png';
import img4 from '../img/Home2.png';
import img3 from '../img/1.png';
import img6 from '../img/2.png';
import img7 from '../img/pexels5.jpg';
import './Home.css'


function Home() {
  return (<>
 <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={img5} className="d-block w-100"  alt="..." />
              <div class="carousel-caption d-none d-md-block">
        <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
            <div className="carousel-item">
              <img src={img2} className="d-block w-100" alt="..." />
              <div class="carousel-caption d-none d-md-block">
              <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
            <div className="carousel-item">
              <img src={img3} className="d-block w-100"  alt="..." />
              <div class="carousel-caption d-none d-md-block">
              <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
            <div className="carousel-item">
              <img src={img4} className="d-block w-100" alt="..." />
              <div class="carousel-caption d-none d-md-block">
              <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
            <div className="carousel-item">
              <img src={img1} className="d-block w-100"  alt="..." />
              <div class="carousel-caption d-none d-md-block">
              <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
            <div className="carousel-item">
              <img src={img6} className="d-block w-100"  alt="..." />
              <div class="carousel-caption d-none d-md-block">
              <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
            <div className="carousel-item">
              <img src={img7} className="d-block w-100"  alt="..." />
              <div class="carousel-caption d-none d-md-block">
              <h5 style={{fontSize:"5rem"}}>BGR GROUP</h5>
        <p style={{fontSize:"2rem"}}>All your textile solution</p>
      </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      <Caraousel></Caraousel>

  </>


  )
}

export default Home
