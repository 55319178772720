import React from 'react'

import lan1 from '../img/lan1.png'
import lan2 from '../img/lan2.png'
import pot1 from '../img/pot1.png'
import pot2 from '../img/pot2.png'
import pot3 from '../img/pot3.png'
import './Accrediation.css'

function Accrediation() {
  return (
    <div>
      <h4 className="accre">Accreditation</h4>
<div className="row1">
  <div class="column1">
    <img class="pt1"src={pot1} />
  </div>
  <div class="column1">
    <img class="pt1" src={pot2} />
  </div>
  <div class="column1">
    <img class="pt1" src={pot3} />
  </div>
</div>

<div class="row2">
  <div class="column2">
    <img class="pt" src={lan1} />
  </div>
  <div class="column2">
    <img class="pt" src={lan2} />
  </div>
</div>
    </div>
  )
}

export default Accrediation