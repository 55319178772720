import React from 'react'
import logo from '../img/BGRGROUPLOGO.jpg'
import './Footer.css';
// import "bootstrap"
// import {Link} from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
// import Iframe from 'react-iframe';


function Footer() {

  return (

   
		<footer class="footer-distributed">

        <div class="footer-left">
             <img class="logos" src={logo} alt="logo" style={{width:"180px"}}/>

<div class="myfo">
<h3>Reg.Add:</h3>
<p class="ka">774 First Floor,
Vishal Nagar,
Isanpur,Ahmedabad,
Gujarat,India-382243.</p>
<br/>
<h3 class="ka1">Office Add:</h3>
<p class="ka1">405,Swaminarayan Plaza-1
Narol Circle,Narol,Ahmedabad,Gujarat,India-382405.</p>
</div>

    <div class="newf">
        <div class="footer-icons">
                <a href="https://www.facebook.com/people/BGR-GROUP/100086410222915/"><i class="fa fa-facebook"></i></a>
            <a href="https://twitter.com/BGRGROUP_Tex"><i class="fa fa-twitter"></i></a>
            <a href="https://www.linkedin.com/company/bgr-traders/"><i class="fa fa-linkedin"></i></a>
            <a href="https://www.youtube.com"><i class="fa fa-youtube"></i></a>
        </div>
    </div>



        </div>

        <div class="footer-center">
            <h3>Links</h3>
            <div class="footer-links">
              <a className="linka" href="/">Home</a>
              <br/>
              <a href="/about" class="linka">About us</a>
              <br/>
              <a class="linka" href="/gallery">Quality policy</a>
              <br/>
              <a class="linka" href="/accrediation">Accreditation</a>
            </div>
        </div>
        <div class="footer-centd">
            <h3>Products</h3>
            <div class="footer-links">
            <a class="linka" href="/yarn">Cotton Yarns</a>
            <br/>
            <a class="linka" href="/synyarn">Synthetic Yarns</a>
            <br/>
            <a class="linka" href="/susyarn">Sustainable Yarns</a>
            <br/>
            </div>

        </div>

        <div class="footer-right">
            <div class="bash">
                <h3>Key Persons</h3>
                <p class="naa">
                    Name: Nikhil Goyal
                    <br/>
                    Mobile: +91 9594765888
                    <br/>
                    Email: director@groupbgr.com
                </p>
        <p class="naa1">
            Name: Aakash Agrawal
                    <br/>
                    Mobile: +91 9638686208
                    <br/>
                    Email: aakash@groupbgr.com
        </p>

            </div>

        </div>
    </footer>
  )


}

export default Footer
