import React from 'react'
import qp from '../img/quality.png'
import './Quality.css'

function Gallery() {
  return (
    <div>
      <div  class="back-cont">
  <img  class="back-img"  src={qp}/>
<div class="homee">
  <h1 class="heading">Quality policy</h1>
</div>
  </div>





<div class="box">

  <h4 class="info">“Quality and purity" starts from the beginning</h4>
  <p class="para">A good beginning ensures a successful ending. Firmly believing in this, at BGR our
commitment to quality and purity starts from the beginning by choosing the finest and the
best sourcing product, and gradually at every stage, it fortifies with more fervor and
dedication. At every stage and every process, a watchful and experienced supervisor makes
sure that the final product leaving the table only elevates the customer experience to a better
level and helps us strengthen our presence securely. The entire process supported by
stringent quality control standards assures that the yarn exported exceedingly matches
international standards and makes us a trusted company across the globe.
</p>
<div class="more-cont">
  <h4 class="bas">In order to achieve above objectives, the company is committed to adhere to following principles and practices:</h4>

<div class="points">

  <h4 class="head-point">Raw material</h4>
  <p class="cont">All raw materials are tested for Fibre length, strength, elongation%, MIC, RD, +B,
Neps/gram, short fibre%, moisture%, trash%, etc. through USTER HVI, USTER AFIS and MAG
equipment. The raw material is issued only after the test results to maintain the quality
parameter</p>
<h4 class="head-point">Process control</h4>
<div class="cont">
  <p >We follow uster 5% standards of international norms.
  <li>21% combing for combed yarns.</li>
  <li>Auto leveller-controlled process to control parameters.</li>
   <li>100% auto coned, siro-cleaned yarns </li></p>
</div>


 <h4 class="head-point">Yarn testing </h4>
 <p class="cont">Every yarn before exporting is inspected by our technical inspectors and following tests are
 done:</p>
 <table>
  <tr>
    <th class="tab1">Average count</th>
    <th class="tab1">Uster%</th>
    <th class="tab1">Obj. faults</th>
    <th class="tab1">TPI</th>
  </tr>
  <tr>
    <td class="tab2">Count CV%</td>
    <td class="tab2">Thin (- 50 \%)</td>
    <td class="tab2">A2+AB+B2+B3+C2</td>
    <td class="tab2">TPI CV%</td>
  </tr>
  <tr>
    <td class="tab2">Strength</td>
    <td class="tab2">Thick (+ 50 \%)</td>
    <td class="tab2"> E + F + G</td>
    <td class="tab2">Elongation</td>
  </tr>
  <tr>
    <td class="tab2">Strength CV%  H 1+H2+I1+I2 </td>
    <td class="tab2">Neps(+ 200 \%)</td>
    <td class="tab2"> H1+H2+I1+I2</td>
    <td class="tab2">Elongation CV% RKM</td>
  </tr>
  <tr>
    <td class="tab2">CSP</td>
    <td class="tab2">Total IPI/Km</td>
    <td class="tab2"> Total classimat faults/Km</td>
    <td class="tab2">RKM CV%</td>
  </tr>
</table>
<h4 class="head-point">Packing</h4>
<p class="cont">Packing is available in cartons, bag & pallets.</p>
</div>
</div>

</div>










    </div>
  )
}

export default Gallery
