// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import AboutUs from './components/About';
import Gallery from './components/Quality';
import Services from './components/Service';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Accrediation from './components/Accrediation';
import Yarn from './components/Yarn'
import SynYarn from './components/SynYarn'
import SusYarn from './components/SusYarn'
import "bootstrap/dist/css/bootstrap.min.css";
import img1 from './img/syn.jpg';
import img2 from './img/synt1.jpg';
import img3 from './img/qp1.jpg';


function App() {
  return (

    <BrowserRouter>
      {/* {Naveen Changes } */}
      <div className="App">
        <Navbar />
       





        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<AboutUs />}></Route>

          <Route path="/service" element={<Services />}></Route>
          <Route path="/yarn" element={<Yarn />}></Route>
          <Route path="/synyarn" element={<SynYarn />}></Route>
          <Route path="/susyarn" element={<SusYarn />}></Route>

          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/accrediation" element={<Accrediation />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter >




  );
}

export default App;
