import React from 'react'
import './About.css'
import bg from "../img/ab3.jpg";
// import ab from '../img/ab.jpg'
import mission from '../img/mission.jpg'

function About() {
  return (
    <div>
      <section class="body">


        <div className="about-section">
          <div class="inner-container">
            <img src={bg} alt="BackGround" className='imgbg' />
            <h1 class="head1">About us</h1>
            <h3>We are a leading supplier of high-quality yarns to customers around the globe. Within a
              short span of 2 years, we have built a reputation for excellence and reliability</h3>
            <p class="text">Our team is dedicated to the finest yarns available, using only the best raw materials and state of the art production techniques. Whether you're looking for natural fibres like cotton, wool, or
              silk, or synthetic fibres like polyester or nylon, or sustainable fibres like banana, hemp, linen we
              have the expertise and resources to meet your needs.</p>
              {/* <img className="ab-section" src={ab3} /> */}

          </div>
        </div>
      </section>
      <section class="Why">
        <div class="middlecontainer">
          <div class="profile">
            <h2 class="why">Why us?</h2>
            {/* <img class="image" src={ab5} /> */}
            <p class="intro">At BGR, we are committed to providing our customers with the highest-quality yarns and
              exceptional customer service. Here are just a few reasons why you should choose us for your
              yarn needs:

              <li class="points">✅Wide selection: We offer a wide range of yarns in various materials, colors, and styles, fibres.
                No matter what you're looking for, we can help you find it.</li>
              <li class="points">✅Expertise: We have a wealth of knowledge and expertise when it comes to yarns. Our team is
                dedicated to sourcing and producing the finest yarns available, using only the best raw
                materials and state of the-art production techniques.</li>
              <li class="points">✅Sustainability and ethics: We take pride in our commitment to sustainability and ethical
                sourcing. We work closely with our suppliers to ensure that all of our yarns are produced in a
                socially and environmentally responsible way.</li>
              <li class="points">✅Exceptional customer service: Our top priority is our customers' satisfaction. We have a
                knowledgeable and responsive sales team, and we offer flexible shipping and payment
                options to make the process as seamless as possible</li>

            </p>
          </div>
        </div>


      </section>
      <section>
        <div class="mission-container">
        <img src={mission}/>
        </div>

      </section>




      <div class="comp">
        <h2 class="com">Our companies and bussiness</h2>

        <h2 class="comp-list">Shree giriraj polytex (Manufacturing pp granules)</h2>
        <h2 class="comp-list">Sandeep polymer (Manufacturing footwear by brand HYTECH )</h2>




      </div>
    </div>





  )
}

export default About
