import React from 'react'
import logo from '../img/BGRGROUPLOGO.jpg';
import {Link} from 'react-router-dom';
import './Navbar.css';
import { useState} from 'react';

function Navbar() {
  const [dropDown,setDropDown] = useState(false);

  return (<>
    <div className='navbar'>
      {/* <div class="navrow"> */}
      <img className="logo" src={logo} />
      <h1 className='navrow'>BGR GROUP</h1>
      {/* </div> */}
       
        <ul className={'nav-links'} >

            <Link to="/" className='home navlink'><li>Home</li></Link>
            <Link to="/about" className='about navlink'><li>About us</li></Link>
            
            <Link className='service products navlink' onClick={()=>{setDropDown(!dropDown)}}><li>Products</li>
            <div className={`dropDown ${dropDown}Products`} onClick={()=>{setDropDown(!dropDown)}}>
              <ul>
               <Link to="/yarn"> <li>Cotton yarns</li> </Link>
               <Link to="/synyarn"> <li>Synthetic yarns</li> </Link>
               <Link to="/susyarn"> <li>Sustainable yarns</li> </Link>
              </ul>
            </div>
            </Link>
            <Link to="/gallery" className='gallery navlink'><li>Quality Policy</li></Link>
            <Link to="/accrediation" className='accrediation navlink'><li>Accreditation</li></Link>
            <Link to="/contact" className='contact navlink'><li>Contact Us</li></Link>
            
        </ul>

        
  


    <input id="menu-toggle" type="checkbox" />
    <label class='menu-button-container' for="menu-toggle">
    <div className="menu-c"><div class='menu-button'></div></div>
    </label>
    <ul class="menu">
           <li><Link to="/" >Home</Link></li>
           <li><Link to="/about" >About us</Link></li>
           <li><Link className='service products navlink' onClick={()=>{setDropDown(!dropDown)}}>Products</Link></li>
           <div className={`dropDown ${dropDown}Products`} onClick={()=>{setDropDown(!dropDown)}}>
              <ul>
               <Link to="/yarn"><li>Cotton yarns</li> </Link> 
               <Link to="/synyarn"> <li>Synthetic yarns</li> </Link>
               <Link to="/susyarn"> <li>Sustainable yarns</li> </Link>
              </ul>
            </div>
           <li><Link to="/gallery">Quality Policy</Link></li>
           <li><Link to="/accrediation">Accrediation</Link></li>
           <li><Link to="/contact">Contact Us</Link></li>
    </ul>
    </div>
    </>
  )
}
// }

export default Navbar