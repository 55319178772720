import React from 'react'
import './Yarn.css'
import cott1 from '../img/cotton.png'
import tab2 from '../img/tab2.jpeg'

function Yarn() {
  return (
    <div>
      <div  class="bac-container">
    <img  class="back-imag"  src={cott1}/>
    <div class="hom">
    <h1 class="hea">Cotton Yarn</h1>
    </div>
    </div>
    <div class="tabc">
      <h4 class="hp">Cotton yarns –</h4>
      <p class="par"> Cotton yarns are made from fibres of cotton
      plant and is known for its softness, breathability and
      absorbency making it ideal for wide range of products including
      clothing, linen and towels</p>
      <h4 class="hp">Cotton blended yarns –</h4>
      <p class="par"> These yarns are made by blending cotton fibre with other fibres, such as
      synthetics fibres like polyester, acrylic or viscose. These yarns produced are stronger than 100%
      cotton yarns.</p>
    </div>

<div class="tabs">
  <img class="tabl" src={tab2}/>
</div>


    </div>
  )
}

export default Yarn
