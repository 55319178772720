import './Caraousel.css'
// import bg from './bg.webp';
import { Link } from 'react-router-dom';
import aditya from './aditya.png'
import c1 from './c1.png'
import c2 from './c2.png'
import c3 from './c3.png'
import c4 from './c4.png'
import cotton from './cotton.png'
import textile from './textile.png'
import yarn from './yarn.png'

import { useEffect } from 'react';
function Caraousel() {
  const h1 = document.getElementById("h1"),
    banner = document.getElementById("banner"),
    button = document.getElementById("button");

  const onScroll = (event) => {
    const scrollPosition = event.target.scrollingElement.scrollTop;
    if (scrollPosition > 150) {
      banner.style.backgroundSize = "150%";
      h1.style.opacity = 0;
      h1.style.translate = "0 -50px";
      h1.style.scale = "0.9";
      button.style.opacity = 0;
      button.style.translate = "0 -50px";
      button.style.scale = "0.8";
    } else {
      banner.style.backgroundSize = "180%";
      h1.style.opacity = 1;
      h1.style.translate = 0;
      h1.style.scale = 1;
      button.style.opacity = 1;
      button.style.translate = 0;
      button.style.scale = 1;
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
  }, []);

  return <div>
    <div className='Caraousel'>

      {/* <header id="banner" className="banner">
        <h1 id="h1">BGR Traders</h1>
        <h6 className="h2" >All your Textile Solution </h6>
        <button id="button"><Link to="/contact">Contact Us</Link></button>
      </header> */}
      <section className="container">
        <h2>A textile company</h2>
        <p>
          We are a leading provider of high-quality yarns for a variety of industries including fashion,
          home furnishings, and automotive. With years of experience in the industry, we have
          established a reputation for excellence in both the products we offer and the service we
          provide to our customers.
        </p>

        <p>
          Our team of skilled professionals works tirelessly to source the finest yarns from around the
          globe and bring them to you at competitive prices. We take great pride in the quality of our
          yarns and are confident that you will be satisfied with your purchase.
        </p>

        <p>
          In addition to our wide selection of yarns, we also offer custom sourcing and manufacturing
          services to meet the specific needs of our clients. No matter what your project requires, we
          have the expertise and resources to deliver the perfect yarn solution.
        </p>

      </section>

      <div class="prod">
        <h5 class="pr">Our products</h5>
        <div class="feature">


          {/* <div class="container-fluid"> */}
          <div class="row">
            <div class="feature-class col-lg-4">
              <img class="icon" src={cotton} />
              <h3 class="feature-title">Cotton fibre</h3>
            </div>
            <div class="feature-class col-lg-4 ">
              <img class="icon" src={yarn} />
              <h3 class="feature-title">Yarns </h3>
            </div>
            <div class="feature-class col-lg-4">
              <img class="icon" src={textile} />
              <h3 class="feature-title">Fabrics</h3>

            </div>
            {/* </div> */}
          </div>
        </div>
        <h5 class="pr">Our clients:</h5>

        <img class="press-logo" src={aditya} alt="tc-logo" />
        <img class="press-logo" src={c1} alt="tnw-logo" />
        <img class="press-logo" src={c2} alt="biz-insider-logo" />
        <img class="press-logo" src={c3} alt="mashable-logo" />
        <img class="press-logo" src={c4} alt="mashable-logo" />
      </div>
      <section class="add">



      </section>
    </div>
  </div>
}

export default Caraousel;
